import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Section, Heading, Text, ButtonList, Kicker, Box } from "./ui";

export default function HomepageCta(props) {
  return (
    <Container width="fullbleed" id="cta">
      <Section padding={2}>
        <Heading center>
          {props.kicker && <Kicker center>{props.kicker}</Kicker>}
          {props.heading}
        </Heading>
        <Text as="p" center variant="lead">
          {props.text}
        </Text>
        <ButtonList links={props.links} variant="center" />
        {props.image && (
          <Box padding={2} margin={1} center>
            <GatsbyImage width="20px" alt={props.image.alt} image={getImage(props.image)} />
          </Box>
        )}
      </Section>
    </Container>
  );
}

export const query = graphql`
  fragment HomepageCtaContent on HomepageCta {
    id
    kicker
    heading
    text
    image {
      alt
      id
      gatsbyImageData
    }
    links {
      id
      href
      text
    }
  }
`;
