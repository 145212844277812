import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { Box, ButtonList, Container, Heading, Kicker, Section, Subhead, Text } from "./ui";

export default function Hero(props) {
  return (
    <Section>
      <Container>
     
        <Box>{props.image && <GatsbyImage alt={props.image.alt} image={getImage(props.image)} />}</Box>
        <Box center>
          <Heading as="h1">
            {props.kicker && <Kicker>{props.kicker}</Kicker>}
            {props.h1}
          </Heading>
          <Subhead as="h2">{props.subhead}</Subhead>
          <Text as="p">{props.text}</Text>
          <ButtonList links={props.links} />
        </Box>
      </Container>
    </Section>
  );
}

export const query = graphql`
  fragment HomepageHeroContent on HomepageHero {
    id
    kicker
    h1: heading
    subhead
    text
    links {
      id
      href
      text
    }
    image {
      id
      gatsbyImageData
      alt
    }
  }
`;
