import * as React from "react";
import { graphql } from "gatsby";
import { Section, Container, Heading, Box } from "./ui";
import "./waitlist.css";

const waitlist = (props) => {
  let waitlistSection = null;
  if (typeof window !== `undefined`) {
    const { Waitlist } = require("waitlistapi");
    waitlistSection = (
      <Waitlist
        api_key="H6TR46"
        className="waitlistSection"
        joinWaitlistHeading={props.text}
        waitlist_link="https://www.heyqq.app/"
      />
    );
  }

  return (
    <Container width="fullbleed" id="waitlist">
      <Section padding={2} background="primary" radius="large">
        <Box center>
          <Container>
            <Heading>
              {/* {props.kicker && <Kicker>{props.kicker}</Kicker>} */}
              {props.heading}
            </Heading>

            {waitlistSection}
          </Container>
        </Box>
      </Section>
    </Container>
  );
};

export default waitlist;

export const query = graphql`
  fragment HomepageWaitlistContent on HomepageWaitlist {
    id
    heading
    text
  }
`;
